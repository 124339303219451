import React from 'react';
import { withStyles, Typography } from '@material-ui/core';

const EventHeader = (props) => {
  const event = props.event;
  const categoryColor = (categoryID) => {
		const colors = [{id: 16, color: '#f2a928'}, {id: 17, color: '#AE6143'}, {id: 18, color: '#727f21'}, {id: 19, color: '#D7D4C2'}, {id: 20, color: '#d65d0b'}, {id: 21, color: '#146f84'}, {id: 38, color: '#b41440'}]
		const categoryInfo =  colors.filter(function(info) {
			if(info.id === categoryID){
				return info.color
			}
		})
		if(categoryInfo.length > 0){
			return categoryInfo[0].color
		}
		return '#57493E'
	}
  return(
    <div>
      <div className={props.classes.eventPrincipalClass}>
        {event.hero.large_url ?
          <img src={event.hero.large_url?.replace('/large/', '/original/')}></img>
          :
          <div className={props.classes.notEventImage}></div>}
        <div className='last-div' style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', opacity:  '0.4'}}></div>
        <div className={props.classes.imageHover}>
          {event.category_name !== null ? (
            <div className={props.classes.categoryLabel} style={{backgroundColor: `${categoryColor(event.category_id)}`}} >
              <p >{event.category_name}</p>
            </div>
          ) : (
            null
          )}
        </div>
      </div>
      <div className={props.classes.headerFooter} style={{backgroundColor: `${categoryColor(event.category_id)}`}}>
        <Typography variant='h6' className={props.classes.title}>{event.name}</Typography>
      </div>
    </div>
  )
}

//1. quitar border
//2. poner position relative
//3.poner div con position absolute y el color
const styleSheet = theme => ({
  header: {
    color: 'white',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '40vh',
    textAlign: 'left',
    display: 'table',
    width: '100%',
    '& .header-container': {
      display: 'table-cell',
      verticalAlign: 'middle',
      padding: '0 10%',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      top: 0,
    }
  },
  title: {
		fontWeight: '700',
		fontFamily: 'Lato, sans-serif',
		fontStyle: 'normal',
		fontSize: '30px',
    color: 'white',
    padding: '16px 0px 48px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '17px',
      padding: '16px 0px 20px',
		}
  },
  categoryLabel: {
		color: 'white',
		borderRadius: '5px',
		height: '20px',
		padding: '0 10px',
		display: 'flex',
		alignItems: 'center',
		width: 'fit-content',
		'& p': {
			padding: 'unset',
			margin: 'unset',
			textTransform: 'uppercase',
			fontSize: '15px'
		},
    [theme.breakpoints.down('sm')]: {
			height: '10px',
      '& p': {
        fontSize: '10px'
      },
		},
  },
  imageHover: {
    position:'absolute',
    left: '50%',
    top: '50%',
    marginTop: '24px',
    width: '80%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      height: 'auto',
      marginTop: '8px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
		},
  },
  headerFooter: {
    height: 'auto',
    padding: '0px 10% 16px',
    [theme.breakpoints.down('sm')]: {
			height: 'auto'
		},
  },
  eventPrincipalClass: {
    position: 'relative',
    maxHeight: '100%',
    '& img': {
      width: '100%',
      height: 'auto',
      marginBottom: '-4px',
    },
  },
  notEventImage: {
    width: '100%',
    height: '60px',
    marginBottom: '-4px',
  },
})
export default withStyles(styleSheet)(EventHeader)