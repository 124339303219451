import React from 'react';
import { withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import facebook from '../assets/images/facebook.svg';
import instragram from '../assets/images/instagram.svg';
import twitter from '../assets/images/twitter.svg';
import youtube from '../assets/images/youtube-icon.svg';
import terms from '../assets/files/terms-conditions.pdf';
import logoAndes from '../assets/images/logo-andes-white.svg';
import vimeo from '../assets/images/Vimeo.svg';
import snapchat from '../assets/images/Snapchat.svg';
import linkedin from '../assets/images/LinkedIn.svg';
function Footer(props){
  return(
		<div>
			<div className={props.classes.footerSection1}>
				<div className={props.classes.uniInfo}>
					<div className="andes-logo" ><img src={logoAndes} alt='andes-logo'/></div>
					<div className='contact-info'>
						<div className='address-info'>
							<p style={{margin: '0px !important'}}>
								Cra 1 Nº 18A - 12<br/>
								Bogotá - Colombia<br/>
								Postal code: 111711<br/>
							</p>
						</div>
						<div className='phone-info'>
							<p>
								+(571) 339 49 99<br />
							</p>
						</div>
					</div>
				</div>
				<div className='quick-links'>
					<div className='quick-title'>ENLACES RÁPIDOS</div>
					<div className='terms-conditions'>
						<p><Link to='/' style={{textDecoration: 'none', color: '#818181', width: '100%'}} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Home</Link></p>
						<p><a href={terms} target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#818181'}}>Términos y condiciones</a></p>
						<p><a href='https://secretariageneral.uniandes.edu.co/images/documents/Manual-Politica-Tratamiento-Datos-Personales-2020-Uniandes.pdf' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#818181'}}>Politica de privacidad</a></p>
						<p><a href='https://servicios.uniandes.edu.co/' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#818181'}}>Servicios</a></p>
						<p><a href='https://ghdo.uniandes.edu.co/index.php/es/' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#818181'}}>Administrativos</a></p>
						<p><a href='https://servicios.uniandes.edu.co/consulta-medica/' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#818181'}}>Emergencias: Extensión 0000</a></p>
						<p><a href='https://campusinfo.uniandes.edu.co/recorridovirtual' target="_blank" rel="noopener noreferrer" style={{textDecoration: 'none', color: '#818181'}}>Recorrido Virtual</a></p>
					</div>
				</div>
				<div className='socials-info'>
					<div className='socials-title'>REDES SOCIALES</div>
					<div className='socials-container'>
						<p><a href='https://www.facebook.com/UniandesCol/' target='_blank' rel="noopener noreferrer"><img src={facebook} alt='face'></img></a></p>
						<p><a href='https://www.instagram.com/uniandes/' target='_blank' rel="noopener noreferrer"><img src={instragram} alt='ins'></img></a></p>
						<p><a href='https://twitter.com/Uniandes' target='_blank' rel="noopener noreferrer"><img src={twitter} alt='twi'></img></a></p>
						<p><a href='https://www.youtube.com/user/uniandes' target='_blank' rel="noopener noreferrer"><img src={youtube} alt='youtu'></img></a></p>
						<p><a href='https://vimeo.com/uniandes' target='_blank' rel="noopener noreferrer"><img src={vimeo} alt='vimeo'></img></a></p>
						<p><a href='https://www.snapchat.com/add/uniandesco' target='_blank' rel="noopener noreferrer"><img src={snapchat} alt='snapchat'></img></a></p>
						<p><a href='https://www.linkedin.com/school/universidad-de-los-andes/' target='_blank' rel="noopener noreferrer"><img src={linkedin} alt='linkedin'></img></a></p>
					</div>
				</div>
			</div>
			<div className={props.classes.footerContainer}>
				<div className='terms-conditions1' style={{fontSize: '.6rem', textAlign: 'left', paddingLeft: '0.5%'}}>
					<p>
						Universidad de los Andes | Vigilada Mineducación<br/>
						Reconocimiento como Universidad: Decreto 1297 del 30 de mayo de 1964.<br/>
						Reconocimiento personería jurídica: Resolución 28 del 23 de febrero de 1949 Minjusticia.<br/>
					</p>
				</div>
				<div className='social-media' style={{fontSize: '.6rem', textAlign: 'left'}}>
					<p>© - Derechos Reservados Universidad de los Andes</p>
				</div>
			</div>
		</div>
	)
}

const styleSheet = theme => ({
	footerContainer: {
		left: 0,
		bottom: 0,
		right: 0,
		backgroundColor: '#121212',
		color: '#818181',
		display: 'flex',
		justifyContent: 'space-between',
		fontSize: '13px',
		fontFamily: 'Lato, sans-serif',
		fontWeight: '400',
		alignItems: 'center',
		paddingLeft: '10%',
		paddingRight: '10%',
		'& .social-media': {
			display: 'flex',
			justifyContent: 'space-around',
			width: '20%'
		},
		'& .terms-conditions': {
			display: 'flex',
			justifyContent: 'space-around',
			width: '40%'
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column-reverse',
			'& .terms-conditions': {
				width: '100%',
				flexWrap: 'wrap',
				'& p': {
					flexBasis: '100%',
					margin: '0.5em'
				}
			},
			'& .social-media': {
				width: '50%'
			}
		}
	},
	uniInfo: {
		backgroundColor: '#181818',
		'& .andes-logo': {
			margin: '0 auto',
			textAlign: 'center',
			padding: '40px',
			width: '230px',
			'&:after': {
				content: '""',
				position: 'relative',
				display: 'block',
				bottom: '-15px',
				height: '1px',
				width: '50%',
				margin: '0 auto',
				borderTop: '1px solid #ffef00'
			}
		},
		'& .contact-info': {
			display: 'table',
			margin: '0 auto',
			width: '100%',
			'& .address-info': {
				fontSize: '.75rem',
				color: 'white',
				lineHeight: 1,
				textAlign: 'center',
				display: 'table-cell',
				'& p': {
					lineHeight: 2
				}
			},
			'& .phone-info': {
				fontSize: '.75rem',
				color: 'white',
				lineHeight: 1,
				textAlign: 'center',
				borderLeft: '1px solid #232323',
				display: 'table-cell',
				'& p': {
					lineHeight: 2
				}
			},
		},
		[theme.breakpoints.down('sm')]: {
			'& .contact-info': {
				display: 'flex',
				flexDirection: 'column',
				paddingBottom: '20px',
				'& .address-info': {
					'& p': {
						lineHeight: 2,
						margin: '0px',
					}
				},
				'& .phone-info': {
					'& p': {
						lineHeight: 2,
						margin: '0px',
					}
				},
			},
		},
	},
	footerSection1: {
		backgroundColor: '#181818',
		display: 'flex',
		justifyContent: 'space-between',
		paddingLeft: '10%',
		paddingRight: '10%',
		alignItems: 'center',
		'& .socials-info': {
			width: '20%',
			'& .socials-title': {
				fontSize: '.75rem',
				color: 'white'
			},
			'& .socials-container': {
				display: 'flex',
				justifyContent: 'space-around',
				'& p': {
					margin: '2px 0px 2px',
				}
			},
			'&:before': {
				top: '-18px',
				content: '""',
				position: 'relative',
				display: 'block',
				bottom: '-15px',
				height: '1px',
				width: '50%',
				margin: '0 auto',
				borderTop: '1px solid #ffef00'
			}
		},
		'& .quick-links': {
			marginBottom: '16px',
			'& .quick-title': {
				color: 'white',
				fontSize: '.75rem'
			},
			'& .terms-conditions': {
				'& p': {
					fontSize: '.85rem'
				}
			}
		},
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'space-around',
			flexWrap: 'wrap',
			'& .socials-info': {
				width: '60%',
				'& .socials-container': {
					flexWrap: 'nonwrap',
					'& p': {
						padding: '0px 2px',
					}
				},
			},
		},
	}
});

export default withStyles(styleSheet)(Footer);