import React from "react";
import './Carousel.css';
import rightNav from '../assets/images/right_nav.svg';
import leftNav from '../assets/images/left-nav.svg';

export default function CrsButton({ direction, moveBanner}) {
    return (
        <button
            onClick={moveBanner}
            className={direction === "next" ? "btn-slide next" :"btn-slide prev"}
        >
            <img src={direction === "next" ? rightNav : leftNav} />
        </button>
    )
}