import React, { Component } from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Switch, Route } from 'react-router-dom';
// pick a date util library
import DateFnsUtils from '@date-io/date-fns';
import Menu from './components/Navbar';
import EventRegister from './components/views/EventRegister';
import AboutUs from './components/views/AboutUs';
import Faq from './components/views/Faq';
import NotFound from './components/views/NotFound';
import './App.css';
import Home from './components/views/Home';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import esLocale from "date-fns/locale/es"
const theme = createMuiTheme();
class  App extends Component {
  render(){
    const { match } = this.props;
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale} >
        <MuiThemeProvider theme={theme}>
          <div className="App">
            <Menu />
            <Switch>
              <Route exact path={`${match.path}`} component={Home}/>
              <Route exact path="/:event_uri/register" component={EventRegister}/>
              <Route exact path="/about-us" component={AboutUs}/>
              <Route exact path="/faq" component={Faq}/>
              <Route component={NotFound}/>
            </Switch>
          </div>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

export default App;

// mis aliados sura