import React, { useState, useEffect, useRef } from 'react';
import { withStyles, Button } from '@material-ui/core';
import "react-image-gallery/styles/css/image-gallery.css";
// import { Link } from 'react-router-dom';
import './Carousel.css';
import CrsButton from './CarouselButton';

function Carousel(props){
	const events = props.events

	const carrouselBanners = events.map((event, eventIndex) => ({
		id: eventIndex,
		image: event?.hero.large_url?.replace('/large/', '/original/'),
		title: event?.name,
		date: event?.start_date.split(' ')[0],
		eventUri: event?.event_uri,
		language: event?.language
	}));

	const carrouselLength = carrouselBanners.length;
	const [index, setIndex] = useState(0);

	const nextBanner = () => {
		if(index !== carrouselBanners.length - 1){
			setIndex(index + 1);
		};
		if (index === carrouselBanners.length - 1){
			setIndex(0);
		};
	};

	const prevBanner = () => {
		if(index !== 0){
			setIndex(index - 1);
		};
		if (index === 0){
			setIndex(carrouselBanners.length - 1);
		};
	};

	const bannerDot = index => {
		setIndex(index);
	};

	const initialSwipePosition = useRef(0);
	const finalSwipePosition = useRef(0);
	const swipeAllowedTime = 200;
	var swipeElapsedTime = 0;
	var swipeStartTime = 0;

	const onTouchStartHandler = (event) => {
		swipeStartTime = new Date().getTime();
		const touchPosition = event?.touches[0]?.clientX;
		if (touchPosition) initialSwipePosition.current = touchPosition;
	};
	const onTouchMoveHandler = (event) => {
		const touchPosition = event?.touches[0]?.clientX;
		if (touchPosition) finalSwipePosition.current = touchPosition;
	}
	const onTouchEndHandler = (event) => {
		swipeElapsedTime = new Date().getTime() - swipeStartTime;
		const touchPosition = event?.touches[0]?.clientX;
		if (touchPosition) finalSwipePosition.current = touchPosition;
		if (initialSwipePosition !== 0 && finalSwipePosition !== 0) {
			const swipeThreshold = window.screen.width * 0.1;
			const swipeVector = finalSwipePosition.current - initialSwipePosition.current;
			const swipeDirection = swipeVector / Math.abs(swipeVector);
			const swipeMagnitude = Math.abs(swipeVector);
			if (swipeDirection === 1 && swipeElapsedTime >= swipeAllowedTime && swipeMagnitude > swipeThreshold) prevBanner();
			if (swipeDirection === -1 && swipeElapsedTime >= swipeAllowedTime && swipeMagnitude > swipeThreshold) nextBanner();
		};

		initialSwipePosition.current = 0;
		finalSwipePosition.current = 0;
		swipeElapsedTime = 0;
		swipeStartTime = 0;
	}
	const onTouchCanelHandler = (event) => {
		event.preventDefault();
	}

	useEffect(() => {
		let autoIndex = (index + 1) % carrouselBanners.length;
		let timer = setTimeout(() => setIndex(autoIndex), 5000);
		return () => {
			clearTimeout(timer);
		};
	}, [index, carrouselBanners.length]);

	const bannerImages = carrouselBanners.map((banner, imageIndex) => (
		<div>
			<img
				key={banner.id}
				className="slide"
				src={banner.image}
				alt=""
				style={{
					opacity: imageIndex === index ? 1 : 0,
					width: '100vw',
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
				}}
				onTouchStart={onTouchStartHandler}
				onTouchMove={onTouchMoveHandler}
				onTouchEnd={onTouchEndHandler}
				onTouchCancel={onTouchCanelHandler}
			/>
			<img
				key={banner.id}
				alt=""
				src={banner.image}
				style={{
					width: '100vw',
					visibility: 'hidden',
					display: imageIndex === index ? 'block' : 'none',
				}}
			/>
		</div>
	));

	return (
		<div className={props.classes.allCarousel}>
			<div className="banner-container">
				<div style={{ position: 'relative' }}>
					{!!carrouselLength && bannerImages}
				</div>
			</div>

			<div className={props.classes.carouselFooter}>
				<div className="container-dots">
					{Array.from({length: carrouselLength}).map((dotItem, dotIndex) => (
						<div
							onClick={() => bannerDot(dotIndex)}
							className={index === dotIndex ? "dot active" : "dot"}
						>
						</div>
						))}

				</div>
				<div className={props.classes.navContainter}>
					<CrsButton moveBanner={prevBanner} direction={"prev"}/>
					<CrsButton moveBanner={nextBanner} direction={"next"}/>
				</div>
				<div className={props.classes.carouselInfo}>
					<div className="description-info">
						{carrouselBanners[index]?.title}
						<div className='date-info'>Fecha: {carrouselBanners[index]?.date}</div>
					</div>
					<a href={`https://evento.uniandes.edu.co/${carrouselBanners[index]?.language}/${carrouselBanners[index]?.eventUri}`} target='_blank' rel="noopener noreferrer" className={props.classes.buttonLink}>
						<Button variant="text" color='#FDF039' className={props.classes.button}>
							Ver más
						</Button>
					</a>
				</div>
			</div>
		</div>
	)
}

const styleSheet = theme => ({
	allCarousel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		height: 'auto',
		marginBottom: '44px',
	},
	backgroundClass:{
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: '50% 50%',
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			backgroundSize: 'contain',
			background: 'black',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '75% 50%',
		},
	},
	carouselFooter: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		background: '#F6F6FA 0% 0% no-repeat padding-box',
		boxShadow: '0px 3px 6px #00000029',
		borderRadius: '0px 0px 8px 8px',
		opacity: '1',
		width: '80%',
		height: 'auto',
		minHeight: '132px',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
			background: '#EFEFEF 0% 0% no-repeat padding-box',
		},
	},
	carouselInfo: {
		width: '100%',
		height: 'auto',
		color: 'black',
		position: 'relative',
		bottom: 0,
		opacity: '1',
		display: 'flex',
		alignItems: 'flex-end',
		justifyContent: 'center',
		paddingBottom: '16px',
		marginBottom: '16px',
		fontFamily: 'Lato, sans-serif',
		webkitTransition: 'opacity 0.3s ease-in-out',
		'& .description-info': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			width: '100%',
			height: 'auto',
			marginTop: '16px',
			padding: '0px 27px',
			font: 'normal normal bold 20px/24px Lato',
			letterSpacing: '0px',
			color: '#707070',
			opacity: '1',
			textAlign: 'left',
			justifyContent: 'flex-end',
			'& .date-info': {
				font: 'normal normal normal 14px/17px Lato',
				marginTop: '4px',
				[theme.breakpoints.down('xs')]: {
					textAlign: 'center',
				},
			},
			[theme.breakpoints.down('xs')]: {
				textAlign: 'center',
				marginBottom: '16px',
				alignItems: 'center',
				padding: '0px',
			},
		},
		[theme.breakpoints.down('xs')]: {
			width: '90%',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'flex-end',
			borderRadius: '4px',
		},
	},

	navContainter: {
		display: 'flex',
		width: '70px',
		paddingTop: '16px',
		justifyContent: 'space-between',
	},

	buttonContainer: {
		[theme.breakpoints.down('xs')]: {
			textAlign: 'center'
		}
	},
	button: {
		background: '#FDF039 0% 0% no-repeat padding-box',
		width: '175px',
		height: '35px',
		textTransform: 'initial',
		borderRadius: 'unset',
		boxShadow: '0px 3px 6px #00000029',
		opacity: '1',
		textAlign: 'center',
		font: 'normal normal bold 16px/16px Lato',
		letterSpacing: '0px',
		color: '#211D31',
		padding: '0px',
		'&:hover': {
			background:'#FDF039',
		},
	},
	buttonLink:{
		marginRight: '27px',
		textTransform: 'initial',
		textDecoration: 'none',
		color: '#211D31',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		[theme.breakpoints.down('xs')]: {
			marginRight: '0px',
		},
	},
})

export default withStyles(styleSheet)(Carousel);