import React from 'react';
import { withStyles, Typography, Card, 
	CardHeader, CardContent
} from '@material-ui/core';
import "react-image-gallery/styles/css/image-gallery.css";
import defaultImg from '../assets/images/default.svg';
import moment from 'moment';
// import { Link } from 'react-router-dom';

const EventCard = (props) => {
	const event = props.event
	
	let cardEventHeader = (event) => {
		return(
			<div className={props.classes.media}>
				{headerImg()}
				{event.category_name !== null ? (
					<div className={props.classes.categoryLabel} ><p>{event.category_name}</p></div>
				) : (
					null
				)}	
			</div>
		)
	}

	let headerImg = () => {
		let url = defaultImg
		let eventEndDate = Date.parse(event.end_date)
		let minEndDate = Date.parse('Apr 07, 2020')
		if(event.hero.medium_url !== null && eventEndDate < minEndDate){
			url = event.hero.large_url?.replace('/large/', '/original/')
			return <img src={url} alt='img' style={{objectFit: 'cover', aspectRatio: '2 / 1', width: '100%'}} />
		}
		if(event.logo.medium_url !== null){
			url = event.logo.medium_url
			return <img src={url} alt='img' style={{objectFit: 'cover', aspectRatio: '2 / 1', width: '100%'}} />
		}
		return <div className='event-img-container' style={{display: 'flex', justifyContent: 'center', width:'100%'}}><img src={url} alt='img' style={{objectFit: 'cover', aspectRatio: '2 / 1', width: '70%'}} /></div>
	}

	return (
		<Card className={props.classes.cardContainer}>
			{event.category_name !== null ? (
				<div style={{textAlign: 'left'}}>
					<span className={props.classes.categoryTab}>
						{event.category_name}
					</span>
				</div>
				
			) : (
				null
			)}
			<a href={`https://evento.uniandes.edu.co/${event.language}/${event.event_uri}`} target='_blank' rel="noopener noreferrer" className={props.classes.registerUrl
				}>
				<CardHeader
					component={() => cardEventHeader(event)}
				/>
				<CardContent style={{display: 'flex', alignItems: 'center', padding: 'unset', textAlign: 'left'}}>
					<Typography variant="body2" color="textSecondary" component="div" style={{display: 'flex', alignItems: 'start', flexWrap: 'wrap', flexDirection: 'column'}}>
						<p style={{fontWeight: '700', fontFamily: 'Lato, sans-serif', color: '#3D3D3D', fontStyle: 'normal', fontSize: '15px', margin: '10px 0'}}>{event.name}</p>
						<p style={{margin: 'unset', color: '#3E3E3E'}}><b>Fecha Inicio:</b> {moment(event.start_date).format('YYYY-MM-DD')}</p>
						<p style={{margin: 'unset', color: '#3E3E3E'}}>Hora Inicio: {moment(event.start_date).format('h:mm a')}</p>
						<p style={{margin: 'unset', color: '#3E3E3E'}}><b>Fecha Fin:</b> {moment(event.end_date).format('YYYY-MM-DD')}</p>
						<p style={{margin: 'unset', color: '#3E3E3E'}}>Hora Fin: {moment(event.end_date).format('h:mm a')}</p>
					</Typography>
				</CardContent>
			</a>
		</Card>
	)
}

const categoryColor = (categoryID) => {
	const colors = [{id: 16, color: '#f2a928'}, {id: 17, color: '#AE6143'}, {id: 18, color: '#727f21'}, {id: 19, color: '#D7D4C2'}, {id: 20, color: '#d65d0b'}, {id: 21, color: '#146f84'}, {id: 38, color: '#b41440'}]
	const categoryInfo =  colors.filter(function(info) {
		if(info.id === categoryID){
			return info.color
		}
	})
	if(categoryInfo.length > 0){
		return categoryInfo[0].color
	}

	return '#2F875F'
}

const styleSheet = theme => ({
	cardContainer: {
		boxShadow: 'unset', 
		padding: '15px', 
		marginBottom: '20%',
		[theme.breakpoints.down('sm')]: {
			marginBottom: '5%'
		}
	},
	media: {
		//height: '140px',
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		backgroundColor: '#ececec',
		borderBottom: props => ` 5px solid ${categoryColor(props.event.category_id)}`,
		
	},
	eventLink: {
		textDecoration: 'none !important'
	},
	categoryLabel: {
		position: 'absolute',
		top: 0,
		color: 'white',
		borderRadius: '5px',
		height: '20px',
		padding: '0 10px',
		display: 'flex',
		margin: '10px',
		alignItems: 'center',
		display: 'none',
		backgroundColor: props => `${categoryColor(props.event.category_id)}`,
		'& p': {
			padding: 'unset',
			margin: 'unset',
			textTransform: 'uppercase', 
			fontSize: '10px'
		}
	},
	categoryTab: {
		backgroundColor: props => `${categoryColor(props.event.category_id)}`,
		display: 'inline-flex',
		alignItems: 'center',
		color: 'white',
		height: '30px',
		padding: '0 10px',
		textTransform: 'uppercase',
		fontSize: '10px'
	},
	registerUrl: {
		textDecoration: 'none',
		fontFamily: 'Lato, sans-serif',
		fontSize: '17px',
		fontWeight: '700'
	}
})

export default withStyles(styleSheet)(EventCard);